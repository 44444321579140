/* Ensure the body and HTML take full width and height */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Arial', sans-serif;
    background-color: #f0f0f0; /* A light background color for better contrast */
}

.textcolor {
    z-index: 1;
    color: #ffffff; /* Replace with your desired color */
    padding-bottom: 0.45em;
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Ensure long words break to fit the screen */
    text-align: center; /* Center the text */
}


/* Container styles */
.homeContainer {
    position: relative;
    height: 100vh;
    width: 100vw;
    background-image: url('../HistoryPage/famhouse.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure no scrollbars for the container */
}

/* Overlay for better text readability */
.homeContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); /* Slightly darker tint for better contrast */
    z-index: 1;
}



/* Navbar styling */
.navbar {
    position: fixed; /* Fixed position to keep it at the top */
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure navbar is above other content */
    margin-bottom: 20px;
    padding: 10px; /* Padding for the navbar */
    display: flex;
    justify-content: center;
    background-color: #ffcb2e; /* Background color for the navbar */
    
}
