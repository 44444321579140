body {
    background-color: #1e1e1e;
  }
  /* landingpage.css */
.landing-page-container {
    background-color: #1e1e1e; /* Set your desired background color */
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 60px;
  }
  