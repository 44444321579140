/* General page styling */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    background-color: #fafafa;
  }
  
  /* Container for login box */
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fafafa;
  }
  
  /* Login box styling */
  .login-box {
    background: #fff;
    padding: 40px;
    border: 1px solid #dbdbdb;
    border-radius: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    justify-items: center;
    text-align: center;
  }
  
  /* Title styling */
  .login-box h2 {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: 300;
    color: #262626;
  }
  
  /* Input fields styling */
  .login-box input {
    width: calc(100% - 24px); /* Adjusted width to account for padding */
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    background-color: #fafafa;
    font-size: 14px;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
    text-align: left; /* Align text to the left */
  }
  
  /* Button styling */
  .login-box button {
    width: 100%;
    padding: 12px;
    background-color: #38f050;
    border: none;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
    
    font-size: 16px;
  }
  
  /* Button hover effect */
  .login-box button:hover {
    background-color: #10dd2b;
  }
  
  /* Placeholder styling for input fields */
  .login-box input::placeholder {
    color: #999;
  }
  
  /* Additional text styling */
  .login-box .additional-text {
    margin-top: 20px;
    color: #999;
    font-size: 14px;
  }
  