/* Ensure the body background is white */
body {
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

/* Full-screen white background block */
.background-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: -1; /* Place it behind all other content */
}

.main-content {
    padding-top: 70px; /* Adjust for the fixed navbar */
}

/* Navbar styling */
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffcb2e;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* Style for content sections */
.content-section {
    padding: 20px;
    margin: 20px;
    background-color: floralwhite;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-section h1, .content-section h2 {
    color: #333;
}

/* Table styling */
.content-section table {
    width: 100%; /* Allow table to fit content width */
    border-collapse: collapse;
    margin-top: 20px;
}

.content-section th, .content-section td {
    border: 1px solid #ddd;
    padding: 8px; /* Adjust padding for better fit */
    text-align: center; /* Center align text in table cells */
    font-size: 14px; /* Set a default font size for table text */
}

.content-section th {
    background-color: #f4f4f4;
}

/* Adjust cell width to fit content */
.content-section td, .content-section th {
    white-space: nowrap; /* Prevent text from wrapping */
}

.bold-cell {
    font-weight: bold;
    font-size: 20px;
}

/* Mobile-friendly adjustments */
@media (max-width: 768px) {
    /* Adjust padding and margins for smaller screens */
    .content-section {
        padding: 15px;
        margin: 10px;
    }

    /* Reduce padding in the main content area */
    .main-content {
        padding-top: 60px; /* Adjust for the fixed navbar */
    }

    /* Reduce the navbar height for smaller screens */
    .navbar {
        height: 50px;
    }

    /* Adjust padding within the navbar */
    .navbar {
        padding: 5px;
    }

    /* Adjust table styling for smaller screens */
    .content-section table {
        width: 100%;
        margin-top: 15px;
        font-size: 12px; /* Reduce font size on smaller screens */
    }

    .content-section th, .content-section td {
        padding: 6px;
        font-size: 12px; /* Adjust font size for table cells */
        white-space: normal; /* Allow text wrapping */
        word-wrap: break-word; /* Break long words to fit in cells */
    }

    /* Reduce font sizes for smaller screens */
    .content-section h1, .content-section h2 {
        font-size: 1.5em; /* Reduce heading font size */
    }

    .bold-cell {
        font-size: 18px; /* Adjust bold cell font size */
    }
}