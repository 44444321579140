/* AdminPage.css */

.admin-page {
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff;
}

.admin-page h1, .admin-page h2 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px #0000001a;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensures fixed column widths */
}

th, td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  word-wrap: break-word; /* Ensures text wraps within cells */
  white-space: normal;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #e6e6e6;
}

.admin-page .top-right-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.admin-page .top-right-buttons button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.admin-page .top-right-buttons button:hover {
  background-color: #0056b3;
}

.table-wrapper {
  max-height: 400px; /* Adjust based on your preference */
}

th, td.nickname {
  width: 10%;
}

th, td.family-id,
th, td.achievements,
th, td.profession {
  width: 15%;
}

th, td.address {
  width: 20%;
}

th, td.action-buttons {
  width: 15%;
}

td img {
  max-width: 50px;
  height: auto;
}

@media (max-width: 768px) {
  .admin-page {
    padding: 10px;
  }

  .admin-form {
    width: 100%;
    padding: 10px;
  }

  .admin-page .top-right-buttons {
    flex-direction: column;
    align-items: flex-start;
  }

  .admin-page .top-right-buttons button {
    margin: 5px 0;
    padding: 10px;
    font-size: 14px;
  }

  .table-wrapper {
    overflow-x: auto;
    display: block;
    -webkit-overflow-scrolling: touch;
  }

  table {
    min-width: 600px; /* Adjust based on table content */
  }
}
