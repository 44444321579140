.circle-container {
  position: relative;
  z-index: -1;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  border-radius: 50%;
  backdrop-filter: blur(2px);
  background-color: rgba(138, 138, 138, 0.177);
  border: 2px solid #a6a6a6;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-color {
  color: rgb(255, 255, 255);
}

.circle-card {
  position: absolute;
  z-index: 2;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  background-color: #9c887c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-card img {
  max-width: 120%;
  max-height: 120%;
  object-fit: cover;
  border-radius: 10%;
  transition: transform 0.3s ease;
}

.homeContent {
  position: relative;
  z-index: 1;
  text-align: center;
  
  padding: 20px 40px;
  
  margin-bottom: 20px;

  border-radius: 8px;
  max-width: 90%;
}

.circle-card img:hover {
  transform: scale(1.1);
}

.circle-card p {
  margin: 0;
  padding-top: 5px;
  font-size: 14px;
}

/* Position the children along the circle border */
.card-0 { transform: rotate(0deg) translate(200px) rotate(-0deg); }
.card-1 { transform: rotate(30deg) translate(200px) rotate(-30deg); }
.card-2 { transform: rotate(60deg) translate(200px) rotate(-60deg); }
.card-3 { transform: rotate(90deg) translate(200px) rotate(-90deg); }
.card-4 { transform: rotate(120deg) translate(200px) rotate(-120deg); }
.card-5 { transform: rotate(150deg) translate(200px) rotate(-150deg); }
.card-6 { transform: rotate(180deg) translate(200px) rotate(-180deg); }
.card-7 { transform: rotate(210deg) translate(200px) rotate(-210deg); }
.card-8 { transform: rotate(240deg) translate(200px) rotate(-240deg); }
.card-9 { transform: rotate(270deg) translate(200px) rotate(-270deg); }
.card-10 { transform: rotate(300deg) translate(200px) rotate(-300deg); }
.card-11 { transform: rotate(330deg) translate(200px) rotate(-330deg); }

@media (max-width: 768px) {
  .circle-container {
    width: 300px; /* Increase size for tablets */
    height: 300px;
  }

  .circle-card {
    width: 70px; /* Increase size of circle cards */
    height: 70px;
  }

  /* Adjust positions */
  .card-0 { transform: rotate(0deg) translate(150px) rotate(-0deg); }
  .card-1 { transform: rotate(30deg) translate(150px) rotate(-30deg); }
  .card-2 { transform: rotate(60deg) translate(150px) rotate(-60deg); }
  .card-3 { transform: rotate(90deg) translate(150px) rotate(-90deg); }
  .card-4 { transform: rotate(120deg) translate(150px) rotate(-120deg); }
  .card-5 { transform: rotate(150deg) translate(150px) rotate(-150deg); }
  .card-6 { transform: rotate(180deg) translate(150px) rotate(-180deg); }
  .card-7 { transform: rotate(210deg) translate(150px) rotate(-210deg); }
  .card-8 { transform: rotate(240deg) translate(150px) rotate(-240deg); }
  .card-9 { transform: rotate(270deg) translate(150px) rotate(-270deg); }
  .card-10 { transform: rotate(300deg) translate(150px) rotate(-300deg); }
  .card-11 { transform: rotate(330deg) translate(150px) rotate(-330deg); }
}

@media (max-width: 480px) {
  .circle-container {
    width: 250px; /* Increase size for mobile phones */
    height: 250px;
  }

  .circle-card {
    width: 50px; /* Increase size of circle cards */
    height: 50px;
  }

  .circle-card p {
    font-size: 12px; /* Adjust font size */
  }

  /* Adjust positions */
  .card-0 { transform: rotate(0deg) translate(125px) rotate(-0deg); }
  .card-1 { transform: rotate(30deg) translate(125px) rotate(-30deg); }
  .card-2 { transform: rotate(60deg) translate(125px) rotate(-60deg); }
  .card-3 { transform: rotate(90deg) translate(125px) rotate(-90deg); }
  .card-4 { transform: rotate(120deg) translate(125px) rotate(-120deg); }
  .card-5 { transform: rotate(150deg) translate(125px) rotate(-150deg); }
  .card-6 { transform: rotate(180deg) translate(125px) rotate(-180deg); }
  .card-7 { transform: rotate(210deg) translate(125px) rotate(-210deg); }
  .card-8 { transform: rotate(240deg) translate(125px) rotate(-240deg); }
  .card-9 { transform: rotate(270deg) translate(125px) rotate(-270deg); }
  .card-10 { transform: rotate(300deg) translate(125px) rotate(-300deg); }
  .card-11 { transform: rotate(330deg) translate(125px) rotate(-330deg); }
}
